import React, { useEffect, useState } from "react";
import Header from "../../../../../common/Header";
import PropTypes from "prop-types";
import "../../../css/surveyList.css";
import { useParams, useNavigate } from 'react-router-dom';
import MultiLevelRadar from "./MultiLevelRadar";
import QuestionView from "./QuestionView";
import "../css/reviewMinutes.css";
import TextEditor from "../../../../../common/TextEditor";
import DropDown from "../../../../../common/DropDown";
import PrimaryButton from "../../../../../common/PrimaryButton";
import Constants from "../../../../../config";
import ToastNotify from "../../../../../common/Toast/ToastNotify";
import Loader from "../../../../../common/loader/index";
import OpenQuestions from "./OpenQuestions";
import parse from 'html-react-parser';
import moment from "moment";
import editIcon from "../../../../../assets/editIcon.svg";
import trash from "../../../../../assets/trash.svg";
import BasicModal from "../../../../../common/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import expandMore from "../../../../../assets/expandMore.svg";
import Avatar from "../../../../../assets/Avater.svg";
import MainTable from "../../../../../common/TableOther";
import styles from "../../../../CreateSurvey/css/addQuestion.module.css";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import arrowRight from "../../../../../assets/arrowRight.png";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useCallbackPrompt } from "./useCallbackPrompt";
const propTypes = {
    getSurveyReports: PropTypes.func,
    surveyList: PropTypes.object,
    isLoading: PropTypes.bool,
    reportsValue: PropTypes.string,
    userDetail: PropTypes.any,
    reviewUserListItems: PropTypes.array,
    getReviewUserList: PropTypes.func,
    getReviewMinutes: PropTypes.func,
    reviewMinutesDetails: PropTypes.array,
    createReviewMinutes: PropTypes.func,
    getPeersList: PropTypes.func,
    peerList: PropTypes.array,
    finalizeReviewMinutes: PropTypes.func,
    quarterList: PropTypes.array,
    selectedQuarter: PropTypes.any,
    deleteReviewMinutes: PropTypes.func,
    updateReviewMinutes: PropTypes.func,
    getReviewMinuteDetail: PropTypes.func,
    reviewMinutesDetailData: PropTypes.any,
    shareReviewMinutes: PropTypes.func,
    getReviewMinutesPeer: PropTypes.func,
    reviewMinutesPeer: PropTypes.array,
    getGoalDetails: PropTypes.func,
    goalDetails: PropTypes.array,
    getReportSurvey: PropTypes.func,
    getReportSurveyDetail: PropTypes.func,
    getReportQuestion: PropTypes.func,
};

const performanceOptions = [
    'Under Performing',
    'Performing',
    'Over Performing'
]

const ReviewMinutes = (props) => {

    const [viewType, setViewType] = useState(2);

    const [selectedCategoryId, setSelectedCategoryId] = useState("");

    const [stage, setStage] = useState([]);

    const [headerData, setHeaderData] = useState("");

    const params = useParams();

    const [textEditorValue, setTextEditorValue] = useState('');

    const [successMsg1, setSuccessMsg1] = useState(false);

    const [successMsg2, setSuccessMsg2] = useState(false);

    const [successMsg3, setSuccessMsg3] = useState(false);

    const [saveType, setSaveType] = useState('');

    const [selectedParticipant, setSelectedParticipant] = useState('');

    const [isFinalizedView, setFinalizedView] = useState(0);

    const [reviewerData, setReviewerData] = useState([]);

    const [reviewerDataOther, setReviewerDataOther] = useState([]);

    const [selectedPerformance, setSelectedPerformance] = useState('');

    const [leadError, setLeadError] = useState(false);

    const [createMinutesError, setCreateMinutesError] = useState(false);

    const [loader, setLoader] = useState(true);

    const [viewTypeRadar, setViewTypeRadar] = useState(true);

    // const [saveAndShareButtonClick, setSaveAndShareButtonClick] = useState(false);

    const [selectedQuarter, setSelectedQuarter] = useState("");

    const [deleteModal, setDeleteModal] = useState(false);

    const [id, setId] = React.useState("");

    const [editId, setEditId] = React.useState("");

    const [expanded, setExpanded] = React.useState([]);

    const [successMsg4, setSuccessMsg4] = useState(false);

    const [successMsg5, setSuccessMsg5] = useState(false);

    const [errorMsg, setErrorMsg] = useState(false);

    const [outerViewType, setOuterViewType] = useState(0);

    const [viewGoal, setViewGoal] = useState(false);

    const [isGoalLoading, setGoalLoading] = useState(false);

    const [goalName, setGoalName] = useState('');

    const [radarLoader, setRadarLoader] = useState(true);

    const [reviewMinuteTabData, setReviewMinuteTabData] = useState([]);

    const [showUnsavedModal, setShowUnsavedModal] = useState(false);

    const [quarterEndDateConditionDate, setQuarterEndDateConditionDate] = useState('');

    const [quarterPeerScheduleDate, setQuarterPeerScheduleDate] = useState('');


    const columns = [
        {
            id: "name",
            type: "myTeam",
            identifier: 'surveyId',
            label: Constants.PEER_NAME,
            minWidth: 220
        },
        {
            id: "reviewMinutesStatus",
            label: Constants.MINUTES_FINALIZED,
            align: "center",
            minWidth: 100,
        },
        {
            id: "Action",
            label: Constants.ACTION,
            minWidth: 170,
            align: "center",
            type: "reports",
            buttonText2: Constants.VIEW_GOALS,
        }
    ];

    const typeOptions = [
        { type: "shortText", text: "Short Text" },
        { type: "scale", text: "Ratings" }
    ];

    const navigate = useNavigate();

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showUnsavedModal);

    const handleChange = (panel) => (event, isExpanded) => {
        let arr = [...expanded];
        if (isExpanded) {
            if (!arr.includes(panel)) {
                arr.push(panel);
            }
        } else {
            const i = arr.indexOf(panel)
            if (i > -1) {
                arr.splice(i, 1);
            }
        }
        if (!event.target.className.includes('previewDivCol')) {
            setExpanded(arr);
        }
    };
    useEffect(() => {
        props.getQuarterList({ type: 'all' });
        if (props.selectedQuarter && props.selectedQuarter !== '') {
            setSelectedQuarter(props.selectedQuarter);
        }
        if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            setFinalizedView(1);
        } else if (location.href.indexOf('finaliseReview') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            setFinalizedView(2);
        } else {
            setFinalizedView(0);
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (showUnsavedModal) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [showUnsavedModal]);

    useEffect(() => {
        if (textEditorValue === '' || textEditorValue === '<p><br></p>') {
            setShowUnsavedModal(false);
        }
    }, [textEditorValue])

    useEffect(() => {
        if (props.selectedQuarter && props.selectedQuarter !== '') {
            if (!selectedQuarter) {
                setSelectedQuarter(props.selectedQuarter);
                if (props?.selectedQuarter) {
                    props?.getReviewMinutesPeer(props.selectedQuarter);
                }
            }
            if (props.userDetail?.loggedUserIdNew) {
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    setSelectedQuarter(params.quarterId);
                    setSelectedParticipant(props.userDetail?.loggedUserIdNew);
                    setSelectedPerformance("");
                    props?.getReviewMinutesPeer(params.quarterId);
                    props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
                    props.getReviewMinuteDetail(params.quarterId, params.revieweeId);
                }
                else {
                    if (!selectedQuarter) {
                        props?.getReviewMinutesPeer(props.selectedQuarter);
                        // props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: props.selectedQuarter, reviewerId: selectedParticipant });
                        props.getReviewMinuteDetail(props.selectedQuarter, props.userDetail?.loggedUserIdNew);
                    }
                }
            }
        }
    }, [props.selectedQuarter]);

    useEffect(() => {
        var expandedArr = [];
        if (props?.reviewMinutesDetails?.length) {
            expandedArr.push(props?.reviewMinutesDetails[0]._id);
        }
        setExpanded(expandedArr);
    }, [props?.reviewMinutesDetails]);

    useEffect(() => {
        if (props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId) {
            setSelectedPerformance(props?.reviewMinutesDetailData?.performanceReviewStatus);
        }
        if (props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "") {
            setSelectedPerformance(props?.reviewMinutesDetailData?.performanceReviewStatus);
            props.getReportSurvey({ prc: "", surveyId: props?.reviewMinutesDetailData?.surveyId, teamId: props?.reviewMinutesDetailData?.teamId, clubbedId: props?.reviewMinutesDetailData?.clubbedId, customerSid: localStorage?.getItem("access_token") })
            props.getReportSurveyDetail({ prc: "", surveyId: props?.reviewMinutesDetailData?.surveyId, teamId: props?.reviewMinutesDetailData?.teamId, clubbedId: props?.reviewMinutesDetailData?.clubbedId, customerSid: localStorage?.getItem("access_token") })
            props.getReportQuestion({ prc: "", surveyId: props?.reviewMinutesDetailData?.surveyId, teamId: props?.reviewMinutesDetailData?.teamId, clubbedId: props?.reviewMinutesDetailData?.clubbedId, customerSid: localStorage?.getItem("access_token") })
            props.getReviewUserList(props?.reviewMinutesDetailData?.surveyId);
        }
        else {
            setViewType(2);
            // setRadarLoader(false);
        }
    }, [props.reviewMinutesDetailData]);

    useEffect(() => {
        if (props.peerList?.length) {
            getParsedData1(props.peerList);
        }
    }, [props.peerList]);

    useEffect(() => {
        if (props?.reviewMinutesPeer?.length) {
            setLoader(false);
            getParsedData1(props.reviewMinutesPeer);
            setReviewMinuteTabData(props?.reviewMinutesPeer?.filter(ele => ele.shared == 1));
        }
        else {
            setLoader(true);
        }
    }, [props?.reviewMinutesPeer])

    useEffect(() => {
        if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            setRadarLoader(true);
            setViewTypeRadar(false);
            setOuterViewType(0);
            setFinalizedView(1);
            setSelectedQuarter(params.quarterId);
            setSelectedPerformance("");
            setSelectedParticipant(props.userDetail?.loggedUserIdNew);
            props?.getReviewMinutesPeer(params.quarterId);
            props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
            props.getReviewMinuteDetail(params.quarterId, params.revieweeId);
        }
    }, [location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== ''])

    useEffect(() => {
        if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            let selectedQuarterObject = props.quarterList.find(ele => ele._id === params.quarterId);
            if (selectedQuarterObject) {
                const quarterEndDate = new Date(selectedQuarterObject?.endDate);
                quarterEndDate.setMonth(quarterEndDate.getMonth() + 1);
                quarterEndDate.setDate(16);
                setQuarterEndDateConditionDate(quarterEndDate);
                const quarterPeerDate = new Date(selectedQuarterObject?.peerScheduleDate);
                setQuarterPeerScheduleDate(quarterPeerDate);
            }

        }
    }, [props?.quarterList, props?.reviewMinutesPeer])

    useEffect(() => {
        if (selectedQuarter && selectedParticipant) {
            if (props.userDetail?.loggedUserIdNew) {
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
                }
                else {
                    props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: selectedParticipant });
                }
            }
        }
    }, [selectedQuarter, selectedParticipant])

    useEffect(() => {
        switch (props.status) {
            case "GET_USER_DETAILS_SUCCESS":
                //props.getReviewMinutes(params.id);
                break;
            case "GET_REPORT_GRAPH":
                setRadarLoader(true);
                break;
            case "GET_REPORT_GRAPH_SUCCESS":
                getNewStageData(props);
                if (!props.reportGraphItemsNew.category && props.reportGraphItemsNew.openQuestions && props.reportGraphItemsNew.openQuestions.length) {
                    setViewTypeRadar(false);
                }
                // setLoader(false);
                setRadarLoader(false);
                break;
            case "GET_REPORT_QUESTION_SUCCESS":
                // props.getReportGraph({ prc: "", surveyId: params.id, teamId: params.teamId, clubbedId: params.clubbedId, customerSid: localStorage?.getItem("access_token") })
                if (props.reportQuestionsItems.activeUserList.length != 0) {
                    getAssesseeIndex(props.reportQuestionsItems.activeUserList, props.reportQuestionsItems.header)
                }
                break;
            case "CREATE_REVIEW_MINUTES_SUCCESS":
                // props.getReviewMinutes(params.id);
                setTextEditorValue("");
                saveType === 1 ? setSuccessMsg2(true) : setSuccessMsg1(true);
                setTimeout(() => { saveType === 1 ? setSuccessMsg2(false) : setSuccessMsg1(false); saveType('') }, 2000);
                // setSaveAndShareButtonClick(false);
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
                }
                else {
                    props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: selectedParticipant });
                }
                break;
            case "FINALIZE_REVIEW_MINUTES_SUCCESS":
                setSuccessMsg3(true);
                setTimeout(() => { setSuccessMsg3(false) }, 2000);
                break;
            case "GET_REVIEW_MINUTES_SUCCESS":
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '') {
                    if (props.userDetail?.loggedUserIdNew) {
                        getParsedData2(props.userDetail);
                        setSelectedParticipant(props.userDetail?.loggedUserIdNew);
                        if (props.reviewMinutesDetails.find(ele => ele.userId === props.userDetail?.loggedUserIdNew)) {
                            // setTextEditorValue(props.reviewMinutesDetails.find(ele => ele.userId === props.userDetail?.loggedUserIdNew).reviewMinutesData);
                        }
                    }
                } else if (location.href.indexOf('finaliseReview') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    setSelectedParticipant(params.revieweeId);
                    if (props.reviewMinutesDetails.find(ele => ele.userId === params.revieweeId)) {
                        // setTextEditorValue(props.reviewMinutesDetails.find(ele => ele.userId === params.revieweeId).reviewMinutesData);
                    }
                }
                var expandedArr = [];
                if (props?.reviewMinutesDetails?.length) {
                    expandedArr.push(props?.reviewMinutesDetails[0]._id);
                }

                setExpanded(expandedArr);
                // setReviewMinutesData(props.reviewMinutesDetails);
                //props.getReviewUserList(params.id);
                break;
            case "GET_PEER_LIST_SUCCESS":
                if (props.peerList?.length) {
                    getParsedData1(props.peerList);
                }
                break;
            case "CREATE_REVIEW_MINUTES_ERROR":
                setCreateMinutesError(true);
                setTimeout(() => { setCreateMinutesError(false) }, 2000);
                break;
            case "DELETE_REVIEW_MINUTES_SUCCESS":
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
                }
                else {
                    props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: selectedParticipant });
                }
                setDeleteModal(false);
                setSuccessMsg5(true);
                setId("");
                break;
            case "UPDATE_REVIEW_MINUTES_SUCCESS":
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
                }
                else {
                    props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: selectedParticipant });
                }
                setEditId("");
                setTextEditorValue("");
                // setsuccessMsg(true);
                setSuccessMsg4(true);
                setId("");
                break;
            case "SHARE_REVIEW_MINUTE_SUCCESS":
                if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
                    props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: props.userDetail?.loggedUserIdNew });
                }
                else {
                    props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: selectedParticipant });
                }
                setSuccessMsg2(true);
                break;
            case "SHARE_REVIEW_MINUTE_ERROR":
            case "UPDATE_REVIEW_MINUTES_ERROR":
            case "DELETE_REVIEW_MINUTES_ERROR":
            case "GET_REVIEW_MINUTE_PEERS_ERROR":
                setErrorMsg(true);
                setTimeout(() => { setErrorMsg(false) }, 2000);
                break;
            case "GET_GOAL_DETAILS":
                setGoalLoading(true);
                break;
            case "GET_GOAL_DETAILS_SUCCESS":
                setGoalLoading(false);
                break;
            case "GET_GOAL_DETAILS_ERROR":
                setGoalLoading(false);
                break;
            case "GET_REVIEW_MINUTE_PEERS":
                setLoader(true);
                break;
            case "GET_REVIEW_MINUTE_PEERS_SUCCESS":
                setReviewMinuteTabData(props?.reviewMinutesPeer?.filter(ele => ele.shared == 1));
                setLoader(false);
                break;
        }

    }, [props.status]);

    const getNewStageData = props => {
        let staticStageContent = [];
        let graphData = JSON.parse(JSON.stringify(props.reportGraphItemsNew.comparision.series));

        let newData = []

        graphData?.map((graph) => {
            if (graph.name === "Score" || graph.name === "You") {
                graph.data.map((item) => {
                    newData.push(item)
                })
            }
            if (graph.name === "Peer") {
                graph.data.map((item, index) => {
                    if (newData[index].categoryId === item.categoryId) {
                        newData[index].averagePeer = item.y;
                        newData[index].currentStage = item.currentStage;
                        newData[index].youStageName = item.stageName;
                    }

                })
            }
        })

        newData.map((graph) => {
            staticStageContent.map((staticData) => {
                if (graph.reportStageId === staticData._id) {
                    graph.content = staticData.content
                    graph.category = staticData.category
                    graph.subContent = staticData.subContent
                }
            })
        })
        getCategoryHierarchy(props, newData);
    }

    const getCategoryHierarchy = (props, staticStageContent) => {

        let categoryStructure = props.reportGraphItemsNew?.drillDownData ? JSON.parse(JSON.stringify(props.reportGraphItemsNew?.drillDownData)) : {};
        for (var key in categoryStructure) {
            staticStageContent.map((staticStageContent) => {
                if (key === staticStageContent.categoryId) {
                    staticStageContent.subCategory = categoryStructure[key] ? categoryStructure[key].name === "Score" ? categoryStructure[key] ? categoryStructure[key].data : [] : categoryStructure[key]['0'] ? categoryStructure[key]['0'].data : [] : [];
                    categoryStructure[key] ? categoryStructure[key].name === "Score" ? staticStageContent.subCatData = categoryStructure[key].data
                        :
                        categoryStructure[key].map((subCat) => {
                            if (subCat.name === "You") {
                                staticStageContent.subCatData = subCat.data;
                            }
                            if (subCat.name === "Peer") {
                                subCat.data.map((peerData) => {
                                    staticStageContent.subCatData.map((data) => {
                                        if (peerData.categoryId === data.categoryId) {
                                            data.peerAvg = peerData.y
                                        }
                                    })
                                })

                            }
                        }) : []
                }

            })
        }
        getQuestionData(props, staticStageContent)
    }

    const getQuestionData = (props, staticStageContent) => {
        let questionData = props.reportQuestionsItems?.questions ? JSON.parse(JSON.stringify(props.reportQuestionsItems?.questions)) : {};
        let categoryQuestionData = props.reportQuestionsItems?.CategoryQuestions ? JSON.parse(JSON.stringify(props.reportQuestionsItems?.CategoryQuestions)) : {};
        let activeList = props.reportQuestionsItems?.activeUserList ? JSON.parse(JSON.stringify(props.reportQuestionsItems.activeUserList)) : [];
        let myScore
        for (let i = 0; i < activeList?.length; i++) {
            if (activeList[i] !== "") {
                myScore = i
            }
        }

        for (let i = 0; i < staticStageContent.length; i++) {
            for (var keyValue in categoryQuestionData) {
                if (keyValue == staticStageContent[i].categoryId) {
                    staticStageContent[i].questionData = categoryQuestionData[keyValue];
                }
            }
            if (staticStageContent[i].subCategory && staticStageContent[i].subCategory.length) {
                for (let j = 0; j < staticStageContent[i].subCategory.length; j++) {
                    for (var key in questionData) {
                        if (key == staticStageContent[i].subCategory[j].categoryId) {
                            staticStageContent[i].subCategory[j].question = questionData[key];
                            for (var index in questionData[key]) {
                                let count = 0;
                                let total = 0;
                                for (let a = 0; a < questionData[key][index].userRating.length; a++) {
                                    if (questionData[key][index].userRating[a].value !== "-" && questionData[key][index].userRating[a].value != "DNK" && a != myScore) {
                                        count = count + 1;
                                        total = total + questionData[key][index].userRating[a].value;
                                    }
                                    if (a == myScore) {
                                        staticStageContent[i].subCategory[j].question[index].userRating.unshift(questionData[key][index].userRating[a]);
                                        staticStageContent[i].subCategory[j].question[index].userRating.splice(a + 1, 1);

                                    }
                                }
                                staticStageContent[i].subCategory[j].question[index].averageScore = total / count;
                            }
                        }
                    }
                }
            } else {
                for (var keyData in questionData) {
                    if (keyData == staticStageContent[i].categoryId) {

                        staticStageContent[i].question = questionData[keyData]
                        for (var indexData in questionData[keyData]) {
                            let count = 0;
                            let total = 0;
                            for (let a = 0; a < questionData[keyData][indexData].userRating.length; a++) {
                                if (questionData[keyData][indexData].userRating[a].value !== "-" && questionData[keyData][indexData].userRating[a].value != "DNK" && a != myScore) {
                                    count = count + 1;
                                    total = total + questionData[keyData][indexData].userRating[a].value
                                }
                                if (a == myScore) {
                                    staticStageContent[i].question[indexData].userRating.unshift(questionData[keyData][indexData].userRating[a]);
                                    staticStageContent[i].question[indexData].userRating.splice(a + 1, 1);

                                }
                            }
                            staticStageContent[i].question[indexData].averageScore = total / count;
                        }
                    }
                }
            }
        }
        setStage(staticStageContent)
    }

    const getAssesseeIndex = (data, header) => {
        let indexData = ""
        data.map((newData, index) => {
            if (newData === 1) {
                indexData = index;
            }
        })
        setHeaderData(header[indexData])
        return indexData
    }

    const setCategoryId = (id) => {
        setSelectedCategoryId(id);
    }

    const changeViewType = (value) => {
        setViewType(value);

    }

    const receiveSelectedCategoryIdToDashboard = (selectedId) => {
        setSelectedCategoryId(selectedId);
    }

    const setTextEditorCallBack = (item, value) => {
        setTextEditorValue(value)
        if (value === '' || value === '<p><br></p>') {
            setShowUnsavedModal(false);
            // setShowPrompt(false);
        } else {
            setShowUnsavedModal(true);
        }
    }

    const handleChangeParticipant = (value) => {
        let valueData = value;
        if (value == 'none') {
            valueData = '';
        }
        // setSaveAndShareButtonClick(false);
        setSelectedParticipant(valueData);
        // props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: value });
        // setTextEditorValue(props.reviewMinutesDetails.find(ele => ele.userId === value) ? props.reviewMinutesDetails.find(ele => ele.userId === value).reviewMinutesData : '');
        if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            setFinalizedView(1);
            props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: valueData });

        } else if (location.href.indexOf('finaliseReview') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            setFinalizedView(2);
            props.getReviewMinutes({ userId: params.revieweeId, quarterId: params.quarterId, reviewerId: valueData });
        } else {
            if (props.reviewMinutesDetails.find(ele => ele.userId === valueData) && props.reviewMinutesDetails.find(ele => ele.userId === valueData).status == 1) {
                setFinalizedView(2);
            } else {
                props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: valueData });
                setFinalizedView(0);
            }
        }
    }

    const handleChangePerformance = (value) => {
        setSelectedPerformance(value);
    }

    const getParsedData1 = (users) => {
        let arr = [];
        arr.push({ title: "None", _id: "none" })
        users.map((user) => {
            if (user.self !== 1) {
                arr.push({
                    title: user.name,
                    _id: user.memberId
                })
                if (user?.manager && !(location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId) && !(location.href.indexOf('finaliseReview') > -1)) {
                    setSelectedParticipant(user.memberId);
                }
            }
        })
        setReviewerData(arr);
    }

    const getParsedData2 = (currentUser) => {
        let arr = [];
        // arr.push({ title: "None", _id: "none" })
        arr.push({
            title: currentUser.name,
            _id: currentUser.loggedUserIdNew
        });
        setReviewerDataOther(arr);
    }

    const handleSave = () => {
        let payload = {};
        if (location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '') {
            payload = {
                teamId: params.teamId,
                clubbedId: params.clubbedId,
                revieweeId: params.revieweeId,
                data: {
                    userId: params.revieweeId,
                    reviewerId: props.userDetail?.loggedUserIdNew,
                    reviewMinutes: textEditorValue,
                    quarterId: params.quarterId ? params.quarterId : "",
                    shareReviewMinutes: 0
                }
            };
        }
        else {
            payload = {
                teamId: params.teamId,
                clubbedId: params.clubbedId,
                revieweeId: props.userDetail?.loggedUserIdNew,
                data: {
                    userId: props.userDetail?.loggedUserIdNew,
                    reviewerId: selectedParticipant,
                    reviewMinutes: textEditorValue,
                    quarterId: selectedQuarter ? selectedQuarter : "",
                    shareReviewMinutes: 0
                }
            };
        }
        setSaveType(0);
        props.createReviewMinutes(payload);
    }

    const handleSaveShare = () => {
        let payload = {
            quarterId: selectedQuarter,
            reviewerId: selectedParticipant,
            revieweeId: props?.userDetail?.loggedUserIdNew
        };
        setSaveType(1);
        // setSaveAndShareButtonClick(true);
        if (props?.reviewMinutesDetailData?.surveyId) {
            props?.shareReviewMinutes(props?.reviewMinutesDetailData?.surveyId, payload);
        }
        // props.createReviewMinutes(payload);
    }

    const handleFinalizeMinutes = () => {
        if (props?.reviewMinutesDetailData?.managerId === props.userDetail?.loggedUserIdNew && !selectedPerformance) {
            setLeadError(true);
            setTimeout(() => setLeadError(false), 2000);
        } else {
            const data = {
                surveyId: props?.reviewMinutesDetailData?.surveyId,
                data: {
                    userId: params?.revieweeId,
                    reviewerId: props?.userDetail?.loggedUserIdNew,
                    quarterId: params.quarterId,
                    performanceReviewStatus: props?.reviewMinutesDetailData?.managerId === props.userDetail?.loggedUserIdNew ? selectedPerformance : undefined,
                    isLead: props?.reviewMinutesDetailData?.managerId === props.userDetail?.loggedUserIdNew ? 1 : 0
                }
            }
            props.finalizeReviewMinutes(data);
        }
    }

    const handleChangeQuarter = (value) => {
        setReviewMinuteTabData([]);
        setViewTypeRadar(false);
        setRadarLoader(true);
        setLoader(true);
        props?.getReviewMinutesPeer(value);
        setSelectedQuarter(value);
        props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: value, reviewerId: selectedParticipant });
        props.getReviewMinuteDetail(value, props.userDetail?.loggedUserIdNew);
    }

    const handleClickOpen = (id) => {
        // setsuccessMsg(false);
        setId(id);
        setDeleteModal(true);
    };

    const handleClose = () => {
        setDeleteModal(!deleteModal);
        setId("");
    };

    const ModelContent = ({ handleClose, deleteReviewMinutes, id }) => {
        const onSave = (e) => {
            e.preventDefault();
            deleteReviewMinutes(id);
        };

        return (
            <div>
                <div className="cardModalButton">
                    <PrimaryButton
                        text="Cancel"
                        onClick={handleClose}
                        className="cancelButton"
                    />
                    <PrimaryButton text="Yes" onClick={onSave} className="saveButton" />
                </div>
            </div>
        );
    };

    const handleUpdate = () => {
        props?.updateReviewMinutes(editId, { reviewMinutes: textEditorValue });
    }

    const viewGoals = (item) => {

        setViewGoal(true);
        setGoalName(item.name);
        props.getGoalDetails(item.surveyId, {});

    }

    const handleCloseGoal = () => {
        setViewGoal(false);
        setGoalName('');
    }

    const ModelContentGoal = ({ goalDetails, handleClose, isGoalLoading, goalName }) => {
        return (
            <div>
                <div className='flex-end'>
                    <CloseIcon onClick={handleClose} style={{ color: '#f56102', fontSize: '32px', cursor: 'pointer' }} title="Close" />
                </div>
                <p style={{ fontSize: '20px', fontWeight: 600 }}>{goalName}</p>
                {
                    isGoalLoading ?
                        <Loader isLoading={true} /> :
                        goalDetails.map((item, index) =>
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <Typography
                                    className={styles.previewQuestionTexts}
                                    sx={{ color: "text.secondary" }}
                                >

                                    <div>
                                        <div className={styles.questionFieldPreview}>
                                            <div className={styles.surveyDetailSpan}>
                                                Objective {index + 1}
                                            </div>
                                            <div>
                                                <img
                                                    src={arrowRight}
                                                    alt="arrowRight"
                                                    className={styles.surveyDetailimg}
                                                />
                                            </div>
                                            <div className={styles.surveyDetailQuestion} style={{ textAlign: 'left' }}>
                                                {item?.question}
                                            </div>
                                        </div>
                                        <div className={styles.moreSummary}>
                                            <div className={styles.typeSummary}>
                                                {typeOptions.find(obj => obj.type === item?.type) ? typeOptions.find(obj => obj.type === item?.type).text : item?.type}
                                            </div>
                                            <div className={styles.catSummary}>
                                                {item?.category_name && item?.category_name !== '' ? item?.category_name : '-'}
                                                {
                                                    item?.subcategory_name && item?.subcategory_name !== '' ?
                                                        <React.Fragment>
                                                            <ArrowRightAltIcon style={{ color: '#fff', margin: '0 5px', fontSize: '16px' }} />
                                                            {item?.subcategory_name}
                                                        </React.Fragment> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Typography>
                                {
                                    item?.keyResults && item?.keyResults?.length ?
                                        <div className={styles.explaination} style={{ margin: '5px 0 0 78px' }}>
                                            <div className={styles.surveyDetailSpanSmall}>
                                                {Constants.KEY_RESULTS} :
                                            </div>
                                            <div className={styles.surveyDetailExplain + " genericTextArea"} style={{ textAlign: 'left' }}>
                                                <div>
                                                    {item?.keyResults.map(i => (
                                                        <div key={item.text} className={styles.surveyDetailExplain + " genericTextArea"}>
                                                            <p key={i.text}>{parse(i.text)}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div> : null
                                }
                            </div>
                        )
                }
            </div>
        );
    };

    const clickBackToReviewMinutes = () => {
        navigate('/minutes');
        setViewTypeRadar(false);
        setLoader(true);
        setOuterViewType(1);
        props?.getReviewMinutesPeer(selectedQuarter);
        props.getReviewMinutes({ userId: props.userDetail?.loggedUserIdNew, quarterId: selectedQuarter, reviewerId: selectedParticipant });
        props.getReviewMinuteDetail(selectedQuarter, props.userDetail?.loggedUserIdNew);

    }

    const compareShareMinutes = (data) => {
        let returnData = 1;
        data?.filter((elem) => elem?.reviewerId == selectedParticipant && elem?.createdBy == props?.userDetail?.email)?.map((ele) => {
            if (ele?.shareStatus == 1) {
                returnData = 0;
            }
            else {
                returnData = 1;
            }
        })
        return returnData;
    }

    const compareFinalizeMinutes = (data) => {
        let returnData = 0;
        data?.filter((elem) => elem?.reviewerId == selectedParticipant)?.map((ele) => {
            if (ele?.status == 1) {
                returnData = 1;
            }
        })
        return returnData;
    }

    const ModelUnsavedContent = ({ handleClose }) => {
        // const onSave = (e) => {
        //     e.preventDefault();
        //     setShowPrompt(false);
        //     navigate(currentPath);
        // };

        return (
            <div>
                <div className="cardModalButton">
                    <PrimaryButton
                        text="Cancel"
                        onClick={handleClose}
                        className="cancelButton"
                    />
                    <PrimaryButton text="Yes" onClick={confirmNavigation} className="saveButton" />
                </div>
            </div>
        );
    };
    // const handleCloseUnsaved = () => {
    //     // setShowUnsavedModal(false);
    //     setShowPrompt(false);
    // };


    return (
        <>
            <Header active="Minutes" />
            <div className="review-minutes-wrapper">
                {
                    location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '' ?
                        <div style={{ marginTop: "5%" }}>
                            <p className="top-heading" style={{ fontSize: "20px" }}>{location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' ? props.reviewMinutesPeer?.find(ele => ele.id === params.revieweeId) ? `${props.reviewMinutesPeer?.find(ele => ele.id === params.revieweeId).name}- Minutes` : '' : location.href.indexOf('finaliseReview') > -1 && params.revieweeId && params.revieweeId !== '' ? props.userDetail?.name : props.userDetail?.name}  {props.reportSurveyItems?._id === params.id ? props.reportSurveyItems?.name : ''}</p>
                        </div>

                        :
                        <div className="breadCrumbs" style={{ marginLeft: "-33%", width: "100%" }}>
                            <div className={outerViewType === 0 ? "breadCrumbsStepsActive" : "breadCrumbsSteps"} onClick={() => { setOuterViewType(0); }}>Record Minutes
                                <hr
                                    className={outerViewType === 0 ? "minutesActiveLine" : "minutesInActiveLine"}
                                    onClick={() => { setOuterViewType(0); }}
                                />
                            </div>

                            <div className={outerViewType === 1 ? "breadCrumbsStepsActive" : "breadCrumbsSteps"} style={{ marginLeft: "-10%" }} onClick={() => { setOuterViewType(1); }}>Review Minutes
                                <hr
                                    className={outerViewType === 1 ? "minutesActiveLine" : "minutesInActiveLine"}
                                    onClick={() => { setOuterViewType(1); }}
                                />
                            </div>



                        </div>
                }

                {
                    outerViewType === 1 ?
                        <div id="my-team" style={{ position: "relative" }}>
                            <Loader isLoading={loader} />
                            <div className="flex" style={{ marginLeft: "83%", position: "relative" }}>
                                <DropDown
                                    options={props.quarterList?.length ? props.quarterList : []}
                                    style={{ color: "#333333", marginTop: '10px' }}
                                    updateValue={handleChangeQuarter}
                                    placeholder="Select quarter"
                                    value={selectedQuarter}
                                    label={'Select quarter'}
                                    className="review-dropdown"
                                />
                            </div>
                            {
                                reviewMinuteTabData?.length ?
                                    <div className='myTeamBox' style={{ marginLeft: "50px", marginRight: "50px" }}>
                                        <MainTable
                                            columns={columns}
                                            rows={reviewMinuteTabData}
                                            withoutCheckBox={true}
                                            buttonClick={(item) => viewGoals(item)}
                                            recordMinutesPage={true}
                                            isPagination={false}
                                            selectedQuarter={selectedQuarter}
                                            userDetail={props.userDetail}
                                        />
                                    </div>
                                    :
                                    <p style={{ textAlign: "center", marginTop: "5%" }}>Data not available</p>
                            }

                        </div>
                        :
                        <div className="flex">
                            <div style={{ width: '58%', position: 'relative', marginRight: '10px', marginTop: location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '' ? "3%" : "1%", overflowY: 'auto', padding: '0 5px', height: 'calc(100vh - 180px)' }}>
                                <Loader isLoading={props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "" ? (loader || radarLoader) : loader} />
                                {
                                    location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '' ?
                                        <button className="back-btn-radar" onClick={() => clickBackToReviewMinutes()}><KeyboardBackspaceIcon /> <span className="back-radar-text">Back To My minutes</span></button>
                                        :
                                        null
                                }

                                {
                                    props?.reportQuestionsItems && props?.reportDetailItems && props?.reportGraphItemsNew && props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "" ?
                                        <ul className="flex" style={{ display: "flex", margin: props.isMobileLayout ? '15px 0px 0px' : "15px 0px 0px 27px", padding: 0 }}>
                                            <li className={viewTypeRadar && (viewType == 0 || viewType === 1) ? "macro-view active" : "macro-view"} style={{ listStyle: "none", padding: "8px 12px", fontSize: "12px", height: "15px" }} onClick={() => { setViewTypeRadar(true); setViewType(0); }}>Radar</li>
                                            {
                                                props?.reportGraphItemsNew?.openQuestions && props?.reportGraphItemsNew?.openQuestions?.length ?
                                                    <li className={!viewTypeRadar && viewType !== 2 ? "macro-view active" : "macro-view"} style={{ listStyle: "none", padding: "8px 12px", fontSize: "12px", height: "15px" }} onClick={() => { setViewTypeRadar(false); setViewType(0); }}>Open Question</li>
                                                    :
                                                    null
                                            }
                                            <li className={viewType === 2 ? "macro-view active" : "macro-view"} style={{ listStyle: "none", padding: "8px 12px", fontSize: "12px", height: "15px" }} onClick={() => { setViewType(2); setViewTypeRadar(false); }}>Minutes</li>

                                        </ul>
                                        : null
                                }
                                {/* {
                                    location.href.indexOf('finalize') === -1 && props.reviewMinutesDetails.find(ele => ele.reviewerId === selectedParticipant) && props.reviewMinutesDetails.find(ele => ele.reviewerId === selectedParticipant).status == 1 ?
                                        <div className="finalized-text" style={{ margin: props.isMobileLayout ? '-25px 0px 0px 263px' : "-25px 0px 0px 293px", padding: 0, fontWeight: 600 }}>finalized</div> : null
                                } */}
                                {
                                    props?.reviewMinutesDetailData?.performanceReviewStatus && props?.reviewMinutesPeer?.find(ele => ele.memberId == selectedParticipant)?.manager === 1 ?
                                        <p className="performance-label" style={{ width: "fit-content" }}>{props?.reviewMinutesDetailData?.performanceReviewStatus ? `Performance - ${props?.reviewMinutesDetailData?.performanceReviewStatus}` : ""}</p>
                                        : null
                                }
                                {
                                    location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== ''
                                        ?

                                        new Date() > quarterEndDateConditionDate || new Date() < quarterPeerScheduleDate ?
                                            null :
                                            props.reviewMinutesDetails?.length && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.managerId === props.userDetail?.loggedUserIdNew ?

                                                compareFinalizeMinutes(props.reviewMinutesDetails) ?
                                                    <p className="performance-label" style={{ width: "fit-content", marginLeft: "64%", marginTop: props?.reportQuestionsItems && props?.reportDetailItems && props?.reportGraphItemsNew && props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "" ? "" : "" }}>Review minutes has been Finalized</p>
                                                    :
                                                    props?.reviewMinutesDetails?.filter((elem) => elem?.reviewerId == selectedParticipant)?.length
                                                        ?
                                                        <PrimaryButton
                                                            text={Constants.FINALISE_MINUTES}
                                                            onClick={handleFinalizeMinutes}
                                                            className="reviewsaveButton"
                                                            disabled={!props.reviewMinutesDetails?.length}
                                                            style={{ height: "fit-content", marginLeft: "69%", marginTop: props?.reportQuestionsItems && props?.reportDetailItems && props?.reportGraphItemsNew && props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "" ? "-5%" : "" }}
                                                        /> : null : null
                                        :
                                        props.reviewMinutesDetails?.length && props?.reviewMinutesDetailData?.surveyId && isFinalizedView !== 2 ?
                                            compareShareMinutes(props.reviewMinutesDetails) ?
                                                <div style={{ position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}>
                                                    <PrimaryButton
                                                        text={'Share Minutes'}
                                                        null
                                                        onClick={handleSaveShare}
                                                        className="reviewsaveButton"
                                                        disabled={!props.reviewMinutesDetails?.length}
                                                        style={{ height: "fit-content", marginLeft: "69%", marginTop: props?.reportQuestionsItems && props?.reportDetailItems && props?.reportGraphItemsNew && props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "" ? "-5%" : "" }}
                                                    />
                                                </div>
                                                :
                                                <p className="performance-label" style={{ width: "fit-content", marginLeft: "64%", marginTop: props?.reportQuestionsItems && props?.reportDetailItems && props?.reportGraphItemsNew && props?.reviewMinutesDetailData && props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.surveyId != "" && props?.reviewMinutesDetailData?.teamId && props?.reviewMinutesDetailData?.teamId != "" && props?.reviewMinutesDetailData?.clubbedId && props?.reviewMinutesDetailData?.clubbedId != "" ? "" : "" }}>Review minutes has been shared</p>
                                            : null
                                }
                                {
                                    props.reportQuestionsItems && props.reportDetailItems && props.reportGraphItemsNew && !radarLoader ?
                                        <div style={{ marginTop: "20px", display: viewType == 0 ? 'block' : 'none' }}>
                                            <Loader isLoading={radarLoader} />
                                            {
                                                viewTypeRadar ?
                                                    props?.reportGraphItemsNew?.category ?
                                                        <MultiLevelRadar
                                                            reportSurveyItems={props.reportSurveyItems}
                                                            reportDetailItems={props.reportDetailItems}
                                                            reportGraphItemsNew={props.reportGraphItemsNew}
                                                            reportQuestionsItems={props.reportQuestionsItems}
                                                            viewType={0}
                                                            changeViewType={changeViewType}
                                                            status={props.status}
                                                            browserWidth={props.browserWidth}
                                                            isMobileLayout={props.isMobileLayout}
                                                            totalUsers={props.reportQuestionsItems?.header?.length}
                                                            sendSelectedCategoryIdToDashboard={receiveSelectedCategoryIdToDashboard}
                                                            selectedQuarter={selectedQuarter}
                                                            viewTypeRadar={viewTypeRadar}
                                                        />
                                                        :
                                                        null
                                                    // <p style={{ textAlign: 'center' }}>No Data available</p>
                                                    :
                                                    <OpenQuestions
                                                        openQuestions={props.reportGraphItemsNew.openQuestions}
                                                    />
                                            }
                                        </div>
                                        : null
                                }
                                {
                                    viewType === 2 ?
                                        <div style={{ marginTop: "2%", marginLeft: "2%" }}>
                                            {
                                                props.reviewMinutesDetails?.length ?
                                                    props?.reviewMinutesDetails?.map((data, key) => {
                                                        return (
                                                            <Accordion
                                                                className={
                                                                    key % 2 === 0 ? "activeAccordion" : "inActiveAccordion"
                                                                }
                                                                expanded={expanded.includes(data._id)}
                                                                key={key}
                                                                onChange={(data?.reviewMinutesData || !props?.isDesktopLayout) ? handleChange(data._id) : null}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={(data?.reviewMinutesData || !props?.isDesktopLayout) ? <img src={expandMore} alt="ExpandMore" style={{ marginTop: expanded.includes(data._id) ? '9px' : '-7px' }} /> : <span style={{ width: '11px' }} />}
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                >
                                                                    <Typography
                                                                        sx={{ color: "text.secondary" }}
                                                                        style={{ fontWeight: "600", fontSize: "13px", color: "#000000", width: "97%" }}
                                                                    >
                                                                        <div style={{ width: "100%" }}>
                                                                            <div className="flex minutes-review">
                                                                                {
                                                                                    location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '' ?
                                                                                        <div className="flex minutes-reviewer-name">
                                                                                            <span>{<img src={Avatar} />}</span>
                                                                                            <span style={{ marginTop: "2%", marginLeft: "1%", width: "400px" }}> {props?.reviewMinutesPeer?.find(ele => ele.email == data.createdBy) ? props?.reviewMinutesPeer?.find(ele => ele.email == data.createdBy)?.name : props?.reviewMinutesPeer?.find(ele => ele.memberId == data.reviewerId) ? props?.reviewMinutesPeer?.find(ele => ele.memberId == data.reviewerId)?.name : props?.reviewMinutesPeer?.find(ele => ele.memberId == data.userId) ? props?.reviewMinutesPeer?.find(ele => ele.memberId == data.userId)?.name : props?.userDetail?.name}</span>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="flex minutes-reviewer-name">
                                                                                            <span>   {<img src={Avatar} />}</span>
                                                                                            <span style={{ marginTop: "2%", marginLeft: "1%", width: "400px" }}>{props?.reviewMinutesPeer?.find(ele => ele.email == data.createdBy) ? props?.reviewMinutesPeer?.find(ele => ele.email == data.createdBy)?.name : props?.userDetail?.name}</span>
                                                                                        </div>

                                                                                }
                                                                                <div className="flex minutes-dateTime" style={{ marginTop: "1%" }}><span>{moment.unix(data?.updatedAt.sec).format("ddd DD-MM-YYYY")}</span>
                                                                                    <span>
                                                                                        {
                                                                                            props.userDetail?.email === data.createdBy ?
                                                                                                <div style={{ display: "flex", marginLeft: "14px" }}>
                                                                                                    <div className="minutes-button" style={{ marginRight: "20px", marginTop: "3px" }}>
                                                                                                        <img
                                                                                                            src={editIcon}
                                                                                                            alt="editIcon"

                                                                                                            onClick={() => {
                                                                                                                setEditId(data._id);
                                                                                                                setTextEditorValue(data.reviewMinutesData);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="minutes-button">
                                                                                                        <img
                                                                                                            src={trash}
                                                                                                            alt="trashImg"
                                                                                                            onClick={() => handleClickOpen(data?._id)}
                                                                                                        />
                                                                                                    </div>

                                                                                                </div>
                                                                                                : null
                                                                                        }

                                                                                    </span>
                                                                                </div>

                                                                            </div>

                                                                        </div>

                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails className="accordianInput">
                                                                    <div>
                                                                        <div className="review-minutes-data"
                                                                        >{parse(data.reviewMinutesData)}
                                                                        </div>
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    }) : <p style={{ textAlign: 'center', marginTop: "28%" }}>No Data available</p>
                                            }
                                        </div>
                                        : null
                                }

                                {
                                    props.reportQuestionsItems && props.reportDetailItems && props.reportGraphItemsNew && props.reportGraphItemsNew.category && props.reviewUserListItems ?
                                        <div style={{ display: viewType === 1 ? 'block' : 'none' }}>
                                            <QuestionView
                                                reportDetailItems={props.reportDetailItems}
                                                reportGraphItemsNew={props.reportGraphItemsNew}
                                                reportQuestionsItemsNew={props.reportQuestionsItems}
                                                interative={"interativeReport"}
                                                selectedCategoryId={selectedCategoryId}
                                                selectedSubCategoryId={""}
                                                updateCategoryId={setCategoryId}
                                                categoryId={selectedCategoryId}
                                                reportType={"gn"}
                                                stage={stage}
                                                selfieReport={false}
                                                headerData={headerData}
                                                changeViewType={(value) => setViewType(value)}
                                                isMobileLayout={props.isMobileLayout}
                                                userDetail={props.userDetail}
                                                survey={props.reportSurveyItems}
                                                isAssesseReport={true}
                                                reviewUserListItems={props.reviewUserListItems}
                                                setTextEditorValue={setTextEditorValue}
                                                textEditorValue={textEditorValue}
                                                setTextEditorCallBack={setTextEditorCallBack}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                            <div style={{ width: '40%', marginLeft: "3%", marginTop: location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '' ? "2%" : "-2%", overflowY: 'auto', padding: '0 5px', height: 'calc(100vh - 125px)' }}>
                                <div style={{ margin: '50px 40px 0 0' }}>
                                    {
                                        location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' ?
                                            <div className="flex">
                                                {
                                                    props?.reviewMinutesDetailData?.surveyId && props?.reviewMinutesDetailData?.managerId === props.userDetail?.loggedUserIdNew ?
                                                        new Date() < quarterPeerScheduleDate ?
                                                            null :
                                                            <DropDown
                                                                options={performanceOptions}
                                                                style={{ color: "#333333", marginTop: '10px' }}
                                                                updateValue={handleChangePerformance}
                                                                placeholder="Select performance"
                                                                value={selectedPerformance}
                                                                label={'Select performance'}
                                                                className="performance-dropdown"
                                                            /> : null
                                                }
                                                {
                                                    reviewerDataOther.length ?
                                                        <DropDown
                                                            options={reviewerDataOther}
                                                            style={{ color: "#333333", marginTop: '10px' }}
                                                            updateValue={handleChangeParticipant}
                                                            placeholder="Select Reviewer"
                                                            value={selectedParticipant}
                                                            label={'Reviewer'}
                                                            className="review-dropdown"
                                                            hideAddBtn={true}
                                                        /> : null
                                                }
                                                {
                                                    editId ?
                                                        <>
                                                            <PrimaryButton
                                                                text={"Cancel"}
                                                                onClick={() => { setEditId(""); setTextEditorValue(""); }}
                                                                className="saveButton"
                                                                style={{ color: "#333333", width: "auto", minWidth: "150px", height: "fit-content" }}
                                                                disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                            />
                                                            <PrimaryButton
                                                                text={Constants.UPDATE}
                                                                onClick={handleUpdate}
                                                                className="saveButton"
                                                                style={{ color: "#333333", marginTop: '10px', minWidth: "140px", height: "fit-content" }}
                                                                disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <PrimaryButton
                                                                text={Constants.SAVE}
                                                                onClick={handleSave}
                                                                style={{ color: "#333333", width: "auto", minWidth: "150px", height: "fit-content" }}
                                                                className="saveButton"
                                                                disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                            />
                                                            {/* <PrimaryButton
                                                            text={Constants.SAVEANDSHARE}
                                                            onClick={handleSaveShare}
                                                            className="saveButton"
                                                            disabled={selectedParticipant === '' || textEditorValue === '' || saveAndShareButtonClick}
                                                        /> */}
                                                        </>
                                                }
                                                {/* {
                                                    location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' && params.quarterId && params.quarterId !== '' ?
                                                        <p className="top-heading" style={{ fontSize: "16px" }}>{location.href.indexOf('finalize') > -1 && params.revieweeId && params.revieweeId !== '' ? props.reviewMinutesPeer?.find(ele => ele.id === params.revieweeId) ? `${props.reviewMinutesPeer?.find(ele => ele.id === params.revieweeId).name}- Minutes` : '' : location.href.indexOf('finaliseReview') > -1 && params.revieweeId && params.revieweeId !== '' ? props.userDetail?.name : props.userDetail?.name}  {props.reportSurveyItems?._id === params.id ? props.reportSurveyItems?.name : ''}</p>
                                                        :
                                                        null
                                                } */}
                                            </div>
                                            :
                                            <div className="flex">
                                                <DropDown
                                                    options={reviewerData}
                                                    style={{ color: "#333333", marginTop: '10px' }}
                                                    updateValue={handleChangeParticipant}
                                                    placeholder="Select Reviewer"
                                                    value={selectedParticipant}
                                                    label={'Reviewer'}
                                                    className="review-dropdown"
                                                // hideAddBtn={location.href.indexOf('finaliseReview') > -1 && params.revieweeId && params.revieweeId !== ''}
                                                />
                                                <DropDown
                                                    options={props.quarterList?.length ? props.quarterList : []}
                                                    style={{ color: "#333333", marginTop: '10px', marginLeft: "2%" }}
                                                    updateValue={handleChangeQuarter}
                                                    placeholder="Select quarter"
                                                    value={selectedQuarter}
                                                    label={'Select quarter'}
                                                    className="review-dropdown"
                                                />
                                                {
                                                    editId ?
                                                        <>
                                                            <PrimaryButton
                                                                text={"Cancel"}
                                                                onClick={() => { setEditId(""); setTextEditorValue(""); }}
                                                                className="saveButton"
                                                                style={{ color: "#333333", width: "16%", minWidth: "100px", height: "fit-content" }}
                                                                disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                            />
                                                            <PrimaryButton
                                                                text={Constants.UPDATE}
                                                                onClick={handleUpdate}
                                                                className="saveButton"
                                                                style={{ color: "#333333", marginTop: '10px', minWidth: "100px", height: "fit-content", width: '16%' }}
                                                                disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <PrimaryButton
                                                                text={Constants.SAVE}
                                                                onClick={handleSave}
                                                                style={{ color: "#333333", width: "32%", minWidth: "140px", height: "fit-content" }}
                                                                className="saveButton"
                                                                disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                            />
                                                            {/* <PrimaryButton
                                                            text={Constants.SAVEANDSHARE}
                                                            onClick={handleSaveShare}
                                                            className="saveButton"
                                                            disabled={selectedParticipant === '' || textEditorValue === '' || saveAndShareButtonClick}
                                                        /> */}
                                                        </>
                                                }
                                            </div>
                                    }
                                </div>
                                <TextEditor
                                    textDescription={textEditorValue}
                                    setDescription={setTextEditorCallBack}
                                    maxCharacters={2000}
                                    id={'description'}
                                />
                                <div className="cardModalButton">
                                    {
                                        isFinalizedView === 0 ?
                                            editId ?
                                                <>
                                                    <PrimaryButton
                                                        text={"Cancel"}
                                                        onClick={() => { setEditId(""); setTextEditorValue(""); }}
                                                        className="saveButton"
                                                        disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                    />
                                                    <PrimaryButton
                                                        text={Constants.UPDATE}
                                                        onClick={handleUpdate}
                                                        className="saveButton"
                                                        disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <PrimaryButton
                                                        text={Constants.SAVE}
                                                        onClick={handleSave}
                                                        className="saveButton"
                                                        disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                    />
                                                    {/* <PrimaryButton
                                                            text={Constants.SAVEANDSHARE}
                                                            onClick={handleSaveShare}
                                                            className="saveButton"
                                                            disabled={selectedParticipant === '' || textEditorValue === '' || saveAndShareButtonClick}
                                                        /> */}
                                                </> :
                                            isFinalizedView === 1 ?
                                                editId ?
                                                    <>
                                                        <PrimaryButton
                                                            text={"Cancel"}
                                                            onClick={() => { setEditId(""); setTextEditorValue(""); }}
                                                            className="saveButton"
                                                            disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                        />
                                                        <PrimaryButton
                                                            text={Constants.UPDATE}
                                                            onClick={handleUpdate}
                                                            className="saveButton"
                                                            disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <PrimaryButton
                                                            text={Constants.SAVE}
                                                            onClick={handleSave}
                                                            className="saveButton"
                                                            disabled={selectedParticipant === '' || textEditorValue === '' || textEditorValue === '<p><br></p>'}
                                                        />
                                                        {/* <PrimaryButton
                                                                text={Constants.FINALISE_MINUTES}
                                                                onClick={handleFinalizeMinutes}
                                                                className="saveButton"
                                                                disabled={selectedParticipant === '' || textEditorValue === ''}
                                                            /> */}
                                                    </> : null
                                    }
                                </div>
                            </div>
                        </div>
                }
                {
                    viewGoal ?
                        <BasicModal
                            open={viewGoal}
                            handleClose={handleCloseGoal}
                            className={'view-goal-modal'}
                            content={
                                <ModelContentGoal
                                    handleClose={handleCloseGoal}
                                    goalDetails={JSON.parse(JSON.stringify(props.goalDetails))}
                                    isGoalLoading={isGoalLoading}
                                    goalName={goalName}
                                />
                            }
                        /> : null
                }
                {deleteModal ? (
                    <BasicModal
                        open={deleteModal}
                        handleClose={handleClose}
                        heading="Are you sure you want to delete this review minute ?"
                        content={
                            <ModelContent
                                handleClose={handleClose}
                                deleteReviewMinutes={props?.deleteReviewMinutes}
                                id={id}
                            />
                        }
                    />
                ) : (
                    <></>
                )}
                {successMsg1 ? (
                    <ToastNotify
                        data={"Minutes has been saved Successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {successMsg2 ? (
                    <ToastNotify
                        data={"Minutes has been shared Successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {successMsg3 ? (
                    <ToastNotify
                        data={"Minutes has been finalized and shared Successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {leadError ? (
                    <ToastNotify
                        data={"Please select performance from dropdown first"}
                        openAlert={true}
                        type={"error"}
                    />
                ) : (
                    ""
                )}
                {
                    createMinutesError ? (
                        <ToastNotify
                            data={"Record minutes should not be empty"}
                            openAlert={true}
                            type={"error"}
                        />
                    ) : (
                        ""
                    )
                }
                {successMsg4 ? (
                    <ToastNotify
                        data={"Minutes has been updated Successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {successMsg5 ? (
                    <ToastNotify
                        data={"Minutes has been deleted Successfully"}
                        openAlert={true}
                        type={"success"}
                    />
                ) : (
                    ""
                )}
                {
                    errorMsg ? (
                        <ToastNotify
                            data={"Something went wrong"}
                            openAlert={true}
                            type={"error"}
                        />
                    ) : (
                        ""
                    )
                }
                {showPrompt ? (
                    <BasicModal
                        open={showPrompt}
                        handleClose={cancelNavigation}
                        heading="By clicking yes unsaved data will be discarded. Are you sure?"
                        content={
                            <ModelUnsavedContent
                                handleClose={cancelNavigation}
                                id={id}
                            />
                        }
                    />
                ) : (
                    <></>
                )}
            </div>

        </>
    );
}

ReviewMinutes.propTypes = propTypes;
export default ReviewMinutes;
